<template>
  <v-app class="bg-login">
    <v-main>
      <v-container fluid fill-height>
        <v-layout justify-center>
          <v-flex xs12 sm8 md8 lg8 xl4>
            <v-img
              contain
              class="mt-10 mx-auto d-flex mb-2 logo__img"
              src="@/assets/img/comprasal_login.png"
              max-width="350"
            />
            <v-card class="mt-10 pa-6 pa-sm-6" rounded="lg" v-if="mostrarIdentificaciones">
              <p class="text-h5 text-center primary--text">
                ¿Con qué tipo de usuario desea ingresar?
              </p>

              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-btn block color="primary" class="py-8 white--text" @click="goToIdentidadDigital(`/login`)">
                    <v-icon color="white" class="pr-4">mdi-briefcase</v-icon> Login COMPRASAL
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-btn block color="primary" class="py-8 white--text" @click="goToIdentidadDigital(`${BASE_URL_ID}/oauth/authorize?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URL.replace('/', '%2F')}`)">
                    <v-icon color="white" class="pr-4">mdi-account-circle</v-icon> Login.sv
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
            <v-card class="mt-10 pa-6 pa-sm-6" rounded="lg" v-else>
              <p class="text-h5 text-center primary--text mb-0">
                Validando credenciales...
              </p>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
    <v-spacer class="my-4"></v-spacer>
    <v-footer class="text-center primary py-3">
      <v-row>
        <v-col
          class="white--text font-weight-bold"
          cols="4"
          sm="6"
          md="6"
          align-self="center"
        >
          <v-img src="@/assets/img/gob_white.png" max-width="175px"/>
        </v-col>
        <v-col cols="8" sm="6" md="6" class="text-end" align-self="center">
          <p class="font-weight-thin white--text no-margin mb-0">
            República de El Salvador, C.A.
          </p>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<style scoped>
.no-margin {
  margin: 0 !important;
}
</style>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import jwtDecode from "jwt-decode";

export default {
  name: "ssoView",
  data: () => ({
    BASE_URL_ID: process.env.VUE_APP_IDENTIDAD_DIGITAL_URL,
    CLIENT_ID: process.env.VUE_APP_CLIENT_ID,
    REDIRECT_URL: process.env.VUE_APP_REDIRECT_URL_ID,
    mostrarIdentificaciones: true
  }),
  methods: {
    ...mapActions(["setAuth"]),
    ...mapMutations(["setUserInfo"]),
    goToIdentidadDigital (url) {
      window.open(url, '_parent')
    },
    async decodeToken (userToken) {
      const userDecode = jwtDecode(userToken);
      this.setUserInfo(userDecode);
    }
  },
  computed: {
    ...mapState({
      userInfo: "userInfo",
      token: "token",
    }),
    ...mapState("utils", ["loader", "menu"]),
  },
  async created () {
    localStorage.clear();
    if (this.$route?.query?.code) {
      this.mostrarIdentificaciones = false;
      await this.services.Auth.GetTokenDigitalIdentity(this.$route?.query?.code)
      .then(async (response) => {
        await this.decodeToken(response?.data?.token)
        await this.setAuth(response?.data)
      }).then(() => {
        this.$router.replace('/');
      })
      .catch(async () => {
        await this.$router.replace('/identidad-digital')
      })
    }
  },
};
</script>
